import { MarketingCampaignHeader } from '../../Reporting/dto/MarketingCampaignReportData';

class NumbersHelper {
    public static industryAverageOpenRate(header: MarketingCampaignHeader): number {
        return header.industryAverageOpenRate / 100;
    }

    public static industryAverageClickToOpenRate(header: MarketingCampaignHeader): number {
        return header.industryAverageClickToOpenRate / 100;
    }

    public static percentageDifference(base: number, diffTo: number) {
        if (diffTo == 0) {
            // Percentage difference to 0 doesn't make sense so return 0;
            return 0;
        }
        const diff = (base - diffTo) / Math.abs(diffTo);
        return diff * 100;
    }

    public static percentageString(value: number, base: number): string {
        if (base === 0) {
            return '0%';
        }
        const float = (value / base) * 100;
        return float.toFixed(1) + '%';
    }

    /**
     * @param value - value to add opens to
     * @param total - Total number to calculate the untraceable opens from.
     * - for example the total number of recipients, or current number of recipients when data is filtered.
     * @param header - Campaign header
     * @returns value + the number of untraceable opens calculated
     */
    public static addUntraceableOpens(value: number, total: number, header: MarketingCampaignHeader): number {
        const untraceableOpens = Math.round(total * (header.untraceableOpensPercent / 100));
        return value + untraceableOpens;
    }
}

export default NumbersHelper;
