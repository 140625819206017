import { AppEvent, EventBusInstance, Modal, ModalType } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import EmailType from '../../Models/EmailType';

interface Props {
    uniqueKey: string;
    title: string;
}

const EmailsPreviewModal: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [emailContents, setEmailContents] = useState<string>('');
    const emailFrameRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<EmailType>) => {
            if (event.target !== props.uniqueKey) return;
            setEmailContents(event.message.html);
            setShown(true);
        });
    }, [shown]);

    useEffect(() => {
        if (emailFrameRef.current) {
            emailFrameRef.current.contentWindow?.document.open();
            emailFrameRef.current.contentWindow?.document.write(emailContents);
            emailFrameRef.current.contentWindow?.document.close();
        }
    }, [emailContents, emailFrameRef.current]);

    const onClose = () => {
        setShown(false);
    };

    return (
        <Modal
            className="emails-preview-modal"
            close={onClose}
            isOpen={shown}
            title={props.title}
            type={ModalType.INFO}
            fullScreen={true}
        >
            <iframe style={{ height: '100%', border: 0 }} ref={emailFrameRef}></iframe>
        </Modal>
    );
};

export default EmailsPreviewModal;
